import { Button, Text } from "@nextui-org/react";
import { Fragment, useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Customer from "../components/Customer";
import DashOrder from "../components/DashOrder";
import { AiOutlineHome } from "react-icons/ai";
import moment from "moment";

import Sidebar from "../components/Sidebar";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  //Order Table Sorting Logic
  const [dateFilter, setDateFilter] = useState([
    moment().startOf("month").subtract(60, "days"),
    moment().endOf("month"),
  ]);
  const [orderList, setOrderList] = useState([]);
  const [orderListAdj, setOrderListAdj] = useState([]);
  const [orderSearch, setOrderSearch] = useState("");
  const [orderSort, setOrderSort] = useState({
    field: "date",
    asc: false,
  });
  const changeOrderSort = (field, asc) => {
    setOrderSort({ field: field, asc: asc });
  };

  const fetchOrders = () => {
    setLoading(true);
    fetch(
      `https://sca-token-api.vercel.app/orders?start=${moment(dateFilter[0])
        .startOf("day")
        .toISOString()}&end=${moment(dateFilter[1]).endOf("day").toISOString()}`
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            "There was an error while retrieving data from the server."
          );
        }
        return res.json();
      })
      .then((data) => {
        const newData = data
          .map((doc) => {
            return doc.products.map((prod) => {
              return {
                productName: prod.name,
                details: prod.details,
                codes: prod.codes,
                price: prod.price,
                date: doc.date,
                _id: prod._id,
                category: prod.category,
                orderName: doc.name,
              };
            });
          })
          .flat();
        setOrderList(newData);
        setOrderListAdj(newData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //Fetch Orders
  useEffect(() => {
    fetchOrders();
  }, [dateFilter]);

  //Order Table Sort Logic
  useEffect(() => {
    if (orderList.length > 0) {
      const listCopy = [...orderList];
      let sortOrder;
      if (
        orderSort.field === "orderName" ||
        orderSort.field === "productName" ||
        orderSort.field === "details"
      ) {
        sortOrder = listCopy.sort((a, b) => {
          let fa = a[orderSort.field].toLowerCase();
          let fb = b[orderSort.field].toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else if (orderSort.field === "price") {
        sortOrder = listCopy.sort((a, b) => {
          return a[orderSort.field] - b[orderSort.field];
        });
      } else if (orderSort.field === "codes") {
        sortOrder = listCopy.sort((a, b) => {
          return a[orderSort.field].length - b[orderSort.field].length;
        });
      } else {
        sortOrder = listCopy.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
      }
      setOrderList(sortOrder);
      setOrderListAdj(orderSort.asc ? sortOrder : sortOrder.reverse());
    }
  }, [orderSort]);

  //Order Search Logic
  useEffect(() => {
    if (orderSearch === "" && orderList.length > 0) {
      return setOrderListAdj(orderList);
    }

    if (orderList.length > 0) {
      const timeoutId = setTimeout(() => {
        const regex = new RegExp(orderSearch, "i");
        const allList = [...orderList];
        const filteredList = allList.filter((doc) => {
          return (
            regex.test(doc.orderName) ||
            regex.test(doc.productName) ||
            regex.test(doc.details)
          );
        });
        setOrderListAdj(filteredList);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [orderSearch]);

  //Customer Table Sorting Logic
  const [customerList, setCustomerList] = useState([]);
  const [customerListAdj, setCustomerListAdj] = useState([]);
  const [customerSearch, setCustomerSearch] = useState("");
  const [customerSort, setCustomerSort] = useState({
    field: "fullName",
    asc: false,
  });
  const changeCustomerSort = (field, asc) => {
    setCustomerSort({ field: field, asc: asc });
  };
  const deleteCustomer = (id) => {
    const newFilter = customerList.filter((doc) => {
      return doc._id.toString() !== id.toString();
    });
    setCustomerList(newFilter);
    setCustomerListAdj(newFilter);
  };
  const pushToCustomer = (data) => {
    const newData = [...customerList, data];
    setCustomerList(newData);
    setCustomerListAdj(newData);
  };

  useEffect(() => {
    setLoading(true);
    fetch("https://sca-token-api.vercel.app/customers")
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            "There was an error while retrieving data from the server!"
          );
        }
        return res.json();
      })
      .then((data) => {
        setCustomerList(data);
        setCustomerListAdj(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  //Customer Table Sort
  useEffect(() => {
    if (customerList.length > 0) {
      const listCopy = [...customerList];
      let sortCustomer;
      if (customerSort.field) {
        sortCustomer = listCopy.sort((a, b) => {
          let fa = a[customerSort.field].toLowerCase();
          let fb = b[customerSort.field].toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      }
      setCustomerList(customerList);
      setCustomerListAdj(
        customerSort.asc ? sortCustomer : sortCustomer.reverse()
      );
    }
  }, [customerSort]);

  //Customer Table Search
  useEffect(() => {
    if (customerSearch === "" && customerList.length > 0) {
      return setCustomerListAdj(customerList);
    }

    if (customerList.length > 0) {
      const timeoutId = setTimeout(() => {
        const regex = new RegExp(customerSearch, "i");
        const allList = [...customerList];
        const filteredList = allList.filter((doc) => {
          return regex.test(doc.fullName) || regex.test(doc.city);
        });
        setCustomerListAdj(filteredList);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [customerSearch]);

  return (
    <Fragment>
      <div className="header">
        <Text b>DASHBOARD</Text>
        <Button
          size="sm"
          rounded
          color="secondary"
          auto
          onClick={() => navigate("/")}
        >
          <AiOutlineHome />
        </Button>
      </div>
      <div className="dashDiv">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="viewDiv">
          <Routes>
            <Route
              path="/all"
              element={
                <DashOrder
                  loading={loading}
                  list={orderListAdj}
                  sortFunction={changeOrderSort}
                  asc={orderSort.asc}
                  setValue={setOrderSearch}
                  value={orderSearch}
                  refetch={fetchOrders}
                  date={dateFilter}
                  setDate={setDateFilter}
                />
              }
            />
            <Route
              path="/new-customer"
              element={
                <Customer
                  loading={loading}
                  list={customerListAdj}
                  sortFunction={changeCustomerSort}
                  asc={customerSort.asc}
                  setValue={setCustomerSearch}
                  delete={deleteCustomer}
                  push={pushToCustomer}
                  value={customerSearch}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
